<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
      <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     
      
          <v-row class="mt-0 ml-5">
          
            <v-col cols="12" xl="8" lg="6" md="6" sm="6" xs="3">
              <v-text-field
                v-model="historico.descricao"
                ref="descricao"
                name="Descricao"
                label="Descrição"
                value
                filled
                clearable
                :name="`descricao_${Math.random()}`"
                hide-details
                @keyup.enter="salvar()"
              ></v-text-field>
            </v-col>
             <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="2">
              <v-btn
                small
                @click="salvar()"
                color="success"
              >
                <v-icon left>
                 mdi-content-save-outline
                </v-icon>
                 Salvar
              </v-btn>
            </v-col>
          </v-row>
          
      
    </v-card>
      <v-card class="mt-5">
     <v-row no-gutters>
      <v-col style="padding:6px 0px 6px 0px!important" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">         
    
      <v-data-table
        :headers="headers"
        :items="historicos"
        :loading="false"
        :search="search"
        dense
        no-data-text="Nenhum resultado encontrado!"
        no-results-text="Nenhum resultado encontrado!"
        class="elevation-1"
      >
       <template v-slot:item.chk_ativo="{ item }">
        <v-switch
                flat
                inset
                hide-details=""
                v-model="item.chk_ativo"
                 @change="mudarStatus(item.id,item.chk_ativo)"                
              ></v-switch>
              </template>
       <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            <!-- <v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>-->
            </template>
       
      </v-data-table>  
   
    
     </v-col>
    </v-row>
    </v-card>
    </div>
     <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <v-snackbar
      :timeout="3000"
      :color="corMsg"
      :multi-line="'multi-line'"
      :vertical="'vertical'"
      :top="true"
      v-model="mostraMsg"
    >
      {{ textSnack }}
    </v-snackbar>
  </v-main>
  
  </v-container>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
    this.buscaHistoricos();
    this.mudaCampo("descricao");
  },
  data: () => ({
    mostraMsg:false,
    textSnack:"",
    corMsg:"",
    search: "",
    historico:{
      id:"",
      descricao:"",
      chk_ativo:null
    },
    
    items: [],
    headers: [],
    itemsBread: [
      {
        text: "Cadastros",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Histórico Padrão",
        disabled: false,
        href: "/dashboard/historico_padrao",
      },
    ],
    dialog: false,
    historicos:[],
       headers: [
    
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Ativo",
        value: "chk_ativo",
      },
      
      { text: "Editar", value: "actions", sortable: false },
    ],
    selDel:"",
    selEdit:"",
    dialogDelete:false,
  }),
  methods:{

    editItem(item){
         this.historico = item;
    },
    mudarStatus(id,status){

        axios
        .post(
          `/fin/historico/status`,
          { id: id,chk_status:status, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
          this.buscaHistoricos();
          if(status){
            this.textSnack = "Historico Ativado!";
            this.corMsg = "success";
          }else{
            this.textSnack = "Historico Desativado!";
            this.corMsg = "error";
          }
          
        
          this.mostraMsg = true;
          
          //this.grupos = response.data;
        });
    },
     deleteItemConfirm() {
    //  this.listaLancamentos.splice(this.editedIndex, 1);
      this.dialogDelete = false;
      axios
        .post(
          `/fin/historico/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") }
          
        )
        .then((response) => {
          this.selDel = "";
          this.buscaHistoricos();
          this.textSnack = "Exclusão feito com sucesso!";
        
          this.mostraMsg = true;
          this.corMsg = "success";
          //this.grupos = response.data;
        });
    },

    deleteItem(item) {
      
      this.selDel = item.id;
      this.dialogDelete = true;
    },
     mudaCampo(campo) {
      eval("this.$refs." + campo + ".focus()");
     },
     validacao() {
      if (this.historico.descricao == "") {
        this.textSnack = "Preencha a descrição";
        this.mudaCampo("descricao");
        this.mostraMsg = true;
        this.corMsg = "error";
        return false;
      }
      return true
     }, 
    buscaHistoricos() {
      axios
        .post(
          `/fin/historico/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
        
        )
        .then((response) => {
          this.historicos = response.data;
        });
    },
    salvar(){

        if (!this.validacao()) {
          
          return false;
        }
        axios
        .post(
          `/fin/historico`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            historico: this.historico,
          }
          
        )
        .then((response) => {
          this.buscaHistoricos();
        
          this.textSnack = "Gravado com sucesso!";
          this.mostraMsg = true;
          this.corMsg = "success";
          this.historico.descricao = ""
          this.mudaCampo("descricao");
        })
        .catch((e) => {
        this.loading = false;
          
          if (!Array.isArray(e.response.data)) {
          
            let itens = [];
            for (var prop in e.response.data) {
              itens.push(e.response.data[prop][0]);
            }
         

            this.textSnack = itens.join("");
            this.mostraMsg = true;
            this.corMsg = "error";
          } else {
         
            this.textSnack = e.response.data;

            this.mostraMsg = true;
            this.corMsg = "error";
          }
        });


    }
  }
};
</script>
